<script setup lang="ts">
import { SkyContext } from '@groveco/skylight'

import { NuxtLink } from '#components'

/**
 * We use the provide helper method to ensure that NuxtLink is used as
 * default for all instances of `SkyLink`.
 *
 * NuxtLink intelligently determines whether the link is internal or external and
 * renders it accordingly.
 *
 * @see: https://nuxt.com/docs/api/components/nuxt-link
 */
provide(SkyContext.RoutingTag, NuxtLink)

useHead({
  titleTemplate: (titleChunk) => {
    const endTitle = '| Grove Collaborative'
    return titleChunk
      ? `${titleChunk} ${endTitle}`
      : `Natural Household and Personal Care Products ${endTitle}`
  },
  script: [
    {
      type: 'application/ld+json',
      children: {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        name: 'Grove Collaborative',
        legalName: 'Grove Collaborative, Inc.',
        url: 'https://www.grove.co',
        logo: 'https://images.grove.co/upload/v1549478001/global/Logos/Regular/Grove_Collaborative_Logo.svg',
        foundingDate: '2012',
        founders: [
          {
            '@type': 'Person',
            name: 'Stuart Landesberg',
          },
          {
            '@type': 'Person',
            name: 'Jordan Savage',
          },
          {
            '@type': 'Person',
            name: 'Chris Clark',
          },
        ],
        address: {
          '@type': 'PostalAddress',
          streetAddress: '1301 Sansome St',
          addressLocality: 'San Francisco',
          addressRegion: 'CA',
          postalCode: '94111',
          addressCountry: 'USA',
        },
        contactPoint: {
          '@type': 'ContactPoint',
          contactType: 'customer support',
          telephone: '[+1-844-476-8375]',
          email: 'community@grove.co',
        },
        sameAs: [
          'https://www.instagram.com/grovecollaborative/',
          'https://twitter.com/grovecollab',
          'https://www.facebook.com/GroveCollab/',
          'https://www.pinterest.com/grovecollab/',
          'https://www.youtube.com/channel/UCymHP9mlJIV5yfcuT70e_5w',
          'https://www.glassdoor.com/Overview/Working-at-Grove-Collaborative-EI_IE1359221.11,30.htm',
        ],
      },
    },
  ],
})
</script>

<template>
  <NuxtPage />
</template>
