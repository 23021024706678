<script setup lang="ts">
import { useDebounceFn } from '@vueuse/core'
import type { MenuItem } from '@groveco/http-services'
import { sha256 } from '~/composables/utils/hash'

// Fetch menu items with useAsyncData to ensure that retrieval happens on
// server-side only (value gets cached for client-side access).
const { fetchMenuItems, setMenuItems } = useMenuItems()
const { data } = await useAsyncData<Array<MenuItem>>(
  'menuItems',
  fetchMenuItems
)
if (data.value) {
  setMenuItems(data.value)
}

/**
 * The default Grove site layout, with optionality on notification bar, header,
 * footer and background color display.
 *
 * NOTE:
 * Eventually we may deprecate optionality in favor of one consistent layout
 * for all Grove (e.g. full header with search bar, cart).
 * Decision to deprecate is pending a design and performance review, as the
 * simplified layout may have important site speed benefits.
 */

const {
  query,
  meta: {
    containsFlushContent = false,
    linenBackground = false,
    showFabModal: showFabModalMeta = true,
    showFullFooter = true,
    showLogoOnlyHeader = false,
  },
} = useRoute()

const layoutClasses = computed(() => ({
  LayoutDefault: true,
  'LayoutDefault--WithRowGap': !containsFlushContent,
}))

const contentClasses = computed(() => ({
  LayoutDefault_Content: true,
  'LayoutDefault_Content--LinenBackground': linenBackground,
  'LayoutDefault_Content--Contain': !containsFlushContent,
}))

const hasSeenIncentiveModal = ref(false)
const inactivityTimer = ref()
const showPulse = ref(true)
const showRoundModal = ref(false)
const newPosition = ref(0)
const showFloatingActionButton = ref(showFabModalMeta)
const HAS_SEEN_INCENTIVE_MODAL = 'HAS_SEEN_INCENTIVE_MODAL'
const INACTIVITY_TIME = ref(5 * 1000)
const AUTO_OPEN_SCROLL_DISTANCE = 200
const SCROLL_DEBOUNCE_WAIT = ref(50)
const FAST_SCROLLUP_TIMEOUT = ref(100)
const { isMediumUp } = useSkyBreakpoint()

const {
  title: offerTitle,
  description: offerDescription,
  image: offerImage,
  isOfferApplied,
  code: offerCode,
  id: offerId,
  isBusy: isOfferBusy,
} = useOffer()

const { $events } = useNuxtApp()
const {
  email,
  fullName,
  id: customerId,
  isActive,
  hasPlacedOrder,
  hasUsablePassword,
  plan,
  isAutoship,
  membershipId,
} = useCustomer()

const { isMobile } = useMobile()

const clearInactivityTimer = () => {
  clearTimeout(inactivityTimer.value)
}

const checkInactivity = () => {
  inactivityTimer.value = setTimeout(() => {
    openModal()
  }, INACTIVITY_TIME.value)

  window.onmousemove = clearInactivityTimer
  window.onmousedown = clearInactivityTimer
  window.ontouchstart = clearInactivityTimer
  window.onclick = clearInactivityTimer
  window.onkeydown = clearInactivityTimer
}

const handleShowOnFastScrollUp = useDebounceFn(() => {
  if (!hasSeenIncentiveModal.value && isFastScrollUp()) {
    openModal()
  }
}, SCROLL_DEBOUNCE_WAIT.value)

const isFastScrollUp = () => {
  const lastPosition = window.scrollY

  setTimeout(() => {
    newPosition.value = window.scrollY
  }, FAST_SCROLLUP_TIMEOUT.value)

  const currentSpeed = newPosition.value - lastPosition

  return currentSpeed > AUTO_OPEN_SCROLL_DISTANCE
}

const handleMouseLeave = () => {
  if (isOfferApplied.value && !email.value && !hasSeenIncentiveModal.value) {
    openModal()
  }
}

/**
 * State that tracks the last tracked location.
 */
const lastTrackedLocation = ref({
  path: '',
  query: {},
  origin: '',
})

/**
 * Helper method to track a page view event.
 */
const trackPageView = () => {
  const route = useRoute()
  const currLocation = $events.contexts.page.buildPageLocationContext()
  currLocation.path = `${currLocation.path}${route.hash}`
  $events.emit(
    ...$events.pageView({
      prevLocation: lastTrackedLocation.value,
      location: currLocation,
      project: { name: 'grove-web' },
    })
  )
  lastTrackedLocation.value = currLocation
}

/**
 * Helper method to track a customer update event
 */
const trackCustomerUpdate = async () => {
  if (!customerId.value || isOfferBusy.value) return

  $events.emit(
    ...$events.customerUpdate({
      customer: {
        id: String(customerId.value),
        isActive: isActive.value,
        isVisitor: !hasPlacedOrder.value,
        isAutoship: isAutoship.value,
        hasMembership: Boolean(membershipId.value),
        hasUsablePassword: hasUsablePassword.value,
      },
      project: {
        name: 'grove-web',
      },
    })
  )

  const getCustomerAnalyticsName = () => {
    if (fullName.value) {
      return fullName.value
    } else if (customerId.value) {
      return `Customer ${customerId.value}`
    } else {
      return 'Unknown Customer'
    }
  }

  const hashedEmail = email.value ? await sha256(email.value) : null

  const getCustomerDetails = () => ({
    id: customerId.value,
    name: getCustomerAnalyticsName(),
    hashedEmail,
    email: email.value,
    isActive: isActive.value,
    isVisitor: !hasPlacedOrder.value,
    offerCode: offerCode.value,
    isSno: plan?.value === 10,
    isVip: hasPlacedOrder.value && membershipId.value,
    // we don't have a great way of getting this right now and aren't sending this from the SPA client either
    segmentIds: [],
    offerCodeId: offerId.value,
  })

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'customerChange',
    customer: getCustomerDetails(),
    userId: customerId.value,
    isStaticPageEvent: true,
  })
}

onMounted(() => {
  watchEffect(trackCustomerUpdate)
  const route = useRoute()
  watch(() => route.fullPath, trackPageView, { immediate: true })

  // Detect if we are embedded in the app and switch layouts dynamically.
  if (query.embed === 'true') {
    setPageLayout('embedded')
    return
  }

  watchEffect(() => {
    if (showFloatingActionButton.value) {
      try {
        const modalAlreadyShown = window.localStorage.getItem(
          HAS_SEEN_INCENTIVE_MODAL
        )
        if (modalAlreadyShown) {
          showPulse.value = false
          hasSeenIncentiveModal.value = true
        }
      } catch (error) {
        // local storage errors such as quota exceeded or localStorage API is not supported.
      }

      if (!hasSeenIncentiveModal.value) {
        // check for auto open of incentive modal
        checkInactivity()
        if (isMobile.value) {
          window.addEventListener('scroll', handleShowOnFastScrollUp)
        } else {
          document.documentElement.addEventListener(
            'mouseleave',
            handleMouseLeave
          )
        }
      }
    }
  })
})

const removeListeners = () => {
  if (isMobile.value) {
    window.removeEventListener('scroll', handleShowOnFastScrollUp)
  } else {
    document.documentElement.removeEventListener('mouseleave', handleMouseLeave)
  }
  clearInactivityTimer()
}

onUnmounted(() => {
  removeListeners()
})

const openModal = () => {
  if (email.value) {
    return
  }
  hasSeenIncentiveModal.value = true
  showPulse.value = false
  showRoundModal.value = true
  removeListeners()
  try {
    window.localStorage.setItem(HAS_SEEN_INCENTIVE_MODAL, '1')
  } catch (error) {
    // local storage errors such as quota exceeded or localStorage API is not supported.
  }
}

watchEffect(() => {
  if (
    process.client &&
    query.fab === 'open' &&
    isOfferApplied.value &&
    showFloatingActionButton.value
  ) {
    openModal()
  }
})

const handleClose = (onSuccess = false) => {
  showRoundModal.value = false
  if (onSuccess) {
    showFloatingActionButton.value = false
  }
}
</script>

<template>
  <div :class="layoutClasses">
    <HeaderLogoOnly v-if="showLogoOnlyHeader" />
    <TheHeader v-else />

    <main data-test-id="content" :class="contentClasses">
      <slot />
    </main>

    <TheFooter data-test-id="footer" :hide-sections="!showFullFooter" />

    <button
      v-if="isOfferApplied && !email && showFloatingActionButton"
      class="LayoutDefault_FloatingActionButton"
      :class="{ LayoutDefault_FloatingActionButton_Pulse: showPulse }"
      data-test-id="floating-action-button"
      tabindex="1"
      aria-label="Open gift offer"
      @click="openModal"
      @keyup.enter="openModal"
    >
      <SkyIcon
        class="LayoutDefault_FloatingActionButton_Icon"
        name="gift"
        title="gift icon"
        :size="isMediumUp ? '32px' : '24px'"
        role="presentation"
      />
      <div class="LayoutDefault_FloatingActionButton_Text">FREE</div>
    </button>
    <RoundModal
      v-if="showRoundModal"
      :heading="offerTitle"
      :subhead="offerDescription"
      :image-src="offerImage"
      data-test-id="round-modal"
      @close="handleClose"
    />
  </div>
</template>

<style lang="scss">
.LayoutDefault--WithRowGap {
  row-gap: var(--spacing-6x);
}

.LayoutDefault {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;

  &_Content--Contain {
    @include container-small;
    margin-top: var(--spacing-6x);
    word-break: break-word;

    @include for-medium-up {
      --container-max: #{$container-medium};
    }

    @include for-large-up {
      --container-max: #{$container-large};
    }

    .SkyLink {
      /**
      * Some pages can contain very long links.
      * They should break to prevent horizontal scrolling.
      */
      word-wrap: break-word;
    }
  }

  &_Content--LinenBackground {
    background-color: var(--surface-color-linen);
  }
}

.LayoutDefault_FloatingActionButton {
  position: fixed;
  border: none;
  bottom: var(--spacing-2x);
  right: var(--spacing-2x);
  height: var(--spacing-16x);
  width: var(--spacing-16x);
  border-radius: var(--border-radius-pill);
  background-color: var(--surface-color-leaf);
  z-index: var(--z-index-sticky);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include for-medium-up {
    height: var(--spacing-24x);
    width: var(--spacing-24x);
    bottom: var(--spacing-12x);
    right: var(--spacing-12x);
  }

  &_Icon {
    margin-bottom: var(--spacing-1x);
    fill: var(--text-color-savings);
  }

  &_Text {
    @include type-section('s');

    @include for-medium-up {
      font-size: var(--font-size-75);
      letter-spacing: var(--letter-spacing-loose);
    }
  }

  &_Pulse {
    animation: pulse-animation 2s infinite;
    animation-iteration-count: 5;
  }

  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0 var(--surface-color-leaf);
    }
    100% {
      box-shadow: 0 0 0 var(--spacing-6x) rgba(0, 0, 0, 0);
    }
  }
}
</style>
